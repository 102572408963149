export const getListData = async (iclass) => {
  try {
    const response = await fetch("/API/restfulAPI.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        act: "getlist",
        iclass,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching list data:", error);
    throw error;
  }
};

export const getDetailData = async (uid) => {
  try {
    const response = await fetch("/API/restfulAPI.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        act: "getdata",
        uid,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching detail data:", error);
    throw error;
  }
};
