import Banner from "../../images/App/appbg1.webp";
import PcBanner from "../../images/App/appbg2.webp";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import useDeviceType from "../../hook/useDeviceType";
// import { commonOpacity } from "../../animateConstant";
import vip_host from "../../images/vip/vip_host.webp";
import vip_exclusive from "../../images/vip/vip_exclusive.webp";
import vip_upgrade from "../../images/vip/vip_upgrade.webp";
import { Button } from "semantic-ui-react";
import { Navigate, useNavigate } from "react-router";
import { FaApple, FaYoutube } from "react-icons/fa";
import { BsAndroid2 } from "react-icons/bs";
import { useCallback, useState } from "react";
import DownloadApp from "./DownloadApp";

const DownloadAppPC = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  const navigate = useNavigate();

  const deviceType = useDeviceType();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDownloadApp, setShowDownloadApp] = useState(false);

  const data = [
    {
      icon: FaApple,
      title: i18n("iosDownload"),
      url: "https://zdere.ttyxuude.com/api/c/3vamd07l",
    },
    {
      icon: BsAndroid2,
      image: vip_host,
      title: i18n("androidDownload"),
      url: "https://appdn.911win.co/app/androiddown.php",
    },
    {
      icon: FaApple,
      image: vip_exclusive,
      title: i18n("iosTeach"),
      link: "app",
    },
    {
      icon: FaYoutube,
      image: vip_upgrade,
      title: i18n("iosDownloadVideo"),
      url: "https://www.youtube.com/watch?v=hYeYZfubJwI",
    },
  ];

  const handleClick = useCallback(
    (item) => {
      if (item.url) {
        window.open(item.url, "_blank");
      } else if (item.link === "app") {
        setShowDownloadApp(true); // 设置显示DownloadApp组件的弹窗
      } else {
        navigate(`/${item.link}`);
      }
    },
    [navigate]
  );

  return (
    <motion.main
      //   initial={commonOpacity.initial}
      //   animate={commonOpacity.animate}
      //   exit={commonOpacity.exit}
      //   transition={commonOpacity.transition}
      className="bg-second-color py-14 md:py-20 lg:py-14"
    >
      {showDownloadApp && (
        <DownloadApp setShowDownloadApp={setShowDownloadApp} />
      )}
      <section className="relative h-[250px] sm:max-w-full max-w-[390px] overflow-hidden mb-[25px]">
        <img
          src={deviceType === "PC" ? PcBanner : Banner}
          className="object-cover sm:w-full sm:h-[285px] h-full"
          alt="banner"
        />
        <div className="absolute top-[70px] left-[25px] md:px-20">
          <p className="text-3xl md:text-4xl text-white font-semibold mb-3">
            {i18n("title")}
          </p>
        </div>
      </section>

      <section className="relative sm:max-w-full rounded-lg overflow-hidden m-[25px] md:px-20">
        <div className="mb-4">
          <div className="mt-2 flex flex-col gap-[10px]">
            <div className="grid md:grid-cols-2 gap-[10px]">
              {data.map((item, index) => (
                <div
                  className="bg-gradient-to-r from-[#ffa568] to-[#fff8f4] rounded-lg p-[15px] h-[120px] flex items-center cursor-pointer"
                  onClick={() => handleClick(item)}
                  key={index}
                >
                  {item.icon && (
                    <item.icon className="text-6xl mr-3 text-white" />
                  )}
                  <div>
                    <p className="text-[18px] text-base-deep-dark line-clamp-3 font-semibold mb-[4px]">
                      {item.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </motion.main>
  );
};

export default DownloadAppPC;
