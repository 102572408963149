import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";

const VideoBanner = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  const i18nVideo = (key) => t(`video.${key}`);

  const links = [
    { href: "https://911win.co", text: `${i18n("extraLink")}1` },
    { href: "https://911win.live", text: `${i18n("extraLink")}2` },
    { href: "https://911win.fun", text: `${i18n("extraLink")}3` },
    { href: "https://911win.vip", text: `${i18n("extraLink")}4` },
    { href: "https://win911bet.com", text: `${i18n("extraLink")}5` },
    { href: "https://911live.vip", text: `${i18n("extraLink")}6` },
    { href: "https://911live.online", text: `${i18n("extraLink")}7` },
    { href: "https://911vn.vip", text: `${i18n("extraLink")}8` },
    { href: "https://911vn.me", text: `${i18n("extraLink")}9` },
  ];

  return (
    <div className="video-banner-wrapper">
      <div className="video-iframe-container">
        <iframe
          className="video-iframe"
          src="https://www.youtube.com/embed/hjPS3j5XNss?autoplay=1&controls=0&loop=1&playlist=hjPS3j5XNss&mute=1&start=4&end=190&modestbranding=1&showinfo=0&rel=0&iv_load_policy=3"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Video Title"
        ></iframe>
      </div>
      {/* <ReactPlayer
        className="react-player absolute inset-0 w-full h-screen object-fill"
        url="https://www.youtube.com/watch?v=hjPS3j5XNss"
        playing
        loop
        muted
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: {
              autoplay: 1,
              controls: 0,
              cc_load_policy: 0,
              fs: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              rel: 0,
              showinfo: 0,
              start: 4,
              end: 190,
            },
          },
        }}
      /> */}

      <div className="absolute inset-0 flex flex-col justify-center items-center md:items-start bg-black bg-opacity-50 text-white px-4 md:pl-[20%] text-center">
        <h1 className="text-3xl md:text-6xl lg:text-7xl font-bold mb-2">
          {i18nVideo("through the game")}
        </h1>
        <h1 className="text-left text-main-color text-3xl md:text-6xl lg:text-7xl font-bold mb-4">
          {i18nVideo("Stable investment and profit")}
        </h1>
        <p className="text-white text-center md:text-left text-sm md:text-base mb-8">
          {i18nVideo(
            "911win e-sports is the first choice for metaverse investment, safe and legal with zero risk."
          )}
          <br></br>
          {i18nVideo(
            "Players can withdraw money quickly, with the fastest payment in the industry within 30 seconds."
          )}
        </p>

        <a
          href="https://emoji168.911win.co/signup.php"
          target="_blank"
          rel="noreferrer"
          className="bg-main-color hover:bg-base-white text-white hover:text-black font-bold py-6 px-12 "
        >
          {i18nVideo("Free trial")}
        </a>
        <div className="grid grid-rows-3 grid-flow-col md:grid-rows-2 my-4">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noreferrer"
              className="bg-base-white hover:bg-main-color rounded-md text-black hover:text-white py-3 px-4 m-2 md:ml-0"
            >
              {link.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
