import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer";
import IntroBlock from "../../components/IntroBlock";
import VideoBanner from "../../components/VideoBanner";
import Step from "../../components/Step";

const Home = () => {
  return (
    <section className="pt-[56px] md:pt-[71px]">
      <VideoBanner />
      <IntroBlock />
      <Step />
    </section>
  );
};

export default Home;
