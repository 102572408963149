import React from "react";
import { useTranslation } from "react-i18next";
import mainPC from "../../images/Soccer/pc/main2.webp";
import mainMobile from "../../images/Soccer/mobile/main2.webp";
import soccerLOGO from "../../images/Soccer/pc/LOGO.webp";

const Soccer = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`soccer.content.${key}`);

  const IframeComponent = () => (
    <iframe
      src="https://khaosat.me/embed/survey/copy-du-doan-doi-vo-dich-uefa-euro-cup-cung-911win-0608385"
      className="w-full max-w-[300px] h-[550px] border-none"
      title="911win"
    ></iframe>
  );

  return (
    <section className="flex flex-col items-center gap-[15px] bg-gradient-to-br from-[#08236e] to-[#02000e] py-14 md:py-20 lg:py-14">
      {/* 手機板樣式 */}
      <section className="h-[340px] w-full block md:hidden">
        <div class="!w-full !h-full object-cover relative">
          <img
            className="absolute top-0 left-0 !w-full !h-full object-cover"
            src={mainMobile}
            alt="911win"
          />
        </div>
      </section>
      {/* 電腦板樣式 */}
      <section className="h-[650px] relative w-full hidden md:block">
        <div class="!w-full !h-full object-cover relative">
          <img
            className="block w-full h-full object-none"
            src={mainPC}
            alt="911win"
          />
        </div>
        <div className="absolute top-[5%] right-[10%]">
          <IframeComponent />
        </div>
      </section>
      {/* 手機板的問卷 */}
      <div className="p-4 h-auto block md:hidden">
        <IframeComponent />
      </div>
      <img
        className="block w-full max-w-[550px] "
        src={soccerLOGO}
        alt="911win"
      />
      <p className="text-base-light-white text-2xl md:text-3xl font-semibold text-center px-5">
        {i18n("title")}
      </p>
      <p className="text-2xl font-bold mb-6 text-white text-center px-5">
        {i18n("subTitle")}
      </p>
      <div className="text-base-light-dark w-full max-w-[850px] dark:text-base-light-white p-4 md:p-8">
        <div className="p-4">
          <div className="mb-4">
            <p className="text-2xl font-bold mb-6 text-white">
              {i18n("startDateTitle")}
            </p>
            <ul className="list-disc pl-5 mt-2 flex flex-col gap-[10px] text-base-input-dark-text">
              <li>{i18n("date")}</li>
            </ul>
          </div>
          <div className="mb-4">
            <p className="text-2xl font-bold mb-6 text-white">
              {i18n("Description")}
            </p>
            <ul className="list-disc pl-5 mt-2 flex flex-col gap-[10px] text-base-input-dark-text">
              <li>{i18n("description1")}</li>
              <div className="w-full mb-4 overflow-x-auto">
                <table className="min-w-full border border-gray-300 mb-4 overflow-x-auto">
                  <thead>
                    <tr>
                      <td className="border px-4 py-2 text-center">Facebook</td>
                      <td className="border px-4 py-2 text-center">
                        <a
                          href="https://www.facebook.com/top.911win/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://www.facebook.com/top.911win/
                        </a>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2 text-center">Youtube</td>
                      <td className="border px-4 py-2 text-center">
                        <a
                          href="https://www.youtube.com/@911win9"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://www.youtube.com/@911win9
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 text-center">Telegram</td>
                      <td className="border px-4 py-2 text-center">
                        <a
                          href="https://t.me/win911win"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://t.me/win911win
                        </a>
                        <span>、</span>
                        <a
                          href="https://t.me/top911win"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://t.me/top911win
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <li>{i18n("description2")}</li>
            </ul>
          </div>
          <div className="mb-4">
            <p className="text-2xl font-bold mb-6 text-white">
              {i18n("prize")}
            </p>
            <ul className="list-disc pl-5 mt-2 flex flex-col gap-[10px] text-base-input-dark-text">
              <div className="w-full mb-4 overflow-x-auto">
                <table className="min-w-full border border-gray-300 mb-6 overflow-x-auto">
                  <thead>
                    <tr>
                      <th className="border px-4 py-2">{i18n("prizeName")}</th>
                      <th className="border px-4 py-2">
                        {i18n("prizeContent")}
                      </th>
                      <th className="border px-4 py-2">{i18n("prizeLimit")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-4 py-2 text-center">
                        {i18n("firstPrize")}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        IPHONE15 ProMax 256G
                      </td>
                      <td className="border px-4 py-2 text-center">15</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 text-center">
                        {i18n("secondPrize")}
                      </td>
                      <td className="border px-4 py-2 text-center">IPHONE15</td>
                      <td className="border px-4 py-2 text-center">20</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 text-center">
                        {i18n("thirdPrize")}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        16.888.888 VND
                      </td>
                      <td className="border px-4 py-2 text-center">25</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 text-center">
                        {i18n("fourthPrize")}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        8.888.888 VND
                      </td>
                      <td className="border px-4 py-2 text-center">45</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 text-center">
                        {i18n("fifthPrize")}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        1.688.888VND
                      </td>
                      <td className="border px-4 py-2 text-center">200</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ul>
          </div>
          <div className="mb-4">
            <p className="text-2xl font-bold mb-6 text-white">
              {i18n("activityDescription")}
            </p>
            <ul className="list-disc pl-5 mt-2 flex flex-col gap-[10px] text-base-input-dark-text">
              <li>{i18n("Rule1")}</li>
              <li>{i18n("Rule2")}</li>
              <li>{i18n("Rule3")}</li>
              <li>{i18n("Rule4")}</li>
              <li>{i18n("Rule5")}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Soccer;
