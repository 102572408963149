import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDetailData } from "../../api/api";

const Detail = () => {
  const { uid } = useParams();
  const [detailData, setDetailData] = useState(null);

  useEffect(() => {
    const fetchDetailData = async () => {
      try {
        const data = await getDetailData(uid);
        if (data.code === "0") {
          setDetailData(data.data);
        } else {
          console.error("Error fetching detail data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching detail data:", error);
      }
    };
    fetchDetailData();
  }, [uid]);

  return (
    <section className="my-32 px-6 flex flex-col justify-center">
      {detailData ? (
        <div className="w-full max-w-[750px] mx-auto my-0">
          <h3 className="text-left text-2xl md:text-4xl font-bold mb-4">
            {detailData.subject}
          </h3>
          <div className="w-full md:w-[60%] text-center mb-4">
            <div className="h-1 bg-main-color rounded-lg"></div>
          </div>
          <p className="text-left text-base mb-2">{detailData.s_subject}</p>
          <p className="text-left text-xs mb-4 text-text-second-gray">
            {detailData.createDate}
          </p>
          <div dangerouslySetInnerHTML={{ __html: detailData.message }} />
          <img
            className="w-full max-w-[600px] h-auto my-4"
            src={`/updates/${detailData.hash}/${detailData.pic0}`}
            alt={detailData.pic0str}
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </section>
  );
};

export default Detail;
