export const languageMapping = (language) => {
  switch (language) {
    case "tw":
      return "中文";
    case "viet":
      return "Tiếng việt";
    case "en":
      return "English";
  }
};

export const languageOptions = [
  { value: "viet", label: "Tiếng việt" },
  { value: "tw", label: "中文" },
  { value: "en", label: "English" },
];
