import React from "react";
import { stylesConfig } from "../helpers/stylesConfig";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { Link } from "react-router-dom";
import ShareButton from "./ShareButton";
import { COMMON_ALT } from "../constant";
import FooterSection from "./FooterSection";
import DecorationLine from "./decorationLine";
import ServicePing from "./ServicePing";

const Footer = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);
  const i18nCommon = (key) => t(`common.${key}`);
  const i18nVideo = (key) => t(`video.${key}`);

  const goodSites = [
    { text: "911win Casino", href: "https://emoji168.911win.co/" },
    { text: "World Cup News", href: "https://911fifa.com/" },
    {
      text: i18n("911win gameplay tutorial"),
      href: "https://emoji168.911win.co/service_help4.php",
    },
  ];
  i18n("completeWorldCupInfo");
  const worldCup = [
    {
      text: i18n("completeWorldCupInfo"),
      href: "https://911fifa.com/category/wcnews/",
    },
    {
      text: i18n("Sports Betting Analysis"),
      href: "https://911fifa.com/category/analytics/",
    },
    {
      text: i18n("Betting terms"),
      href: "https://www.facebook.com/keobongdavnuytin",
    },
    {
      text: i18n("List of odds and winning rates"),
      href: "https://emoji168.911win.co/game_sport.php",
    },
  ];
  const score = [
    { text: "EGAMERSWORLD", href: "https://egamersworld.com" },
    { text: "SofaScore", href: "https://www.sofascore.com/" },
    { text: "Flashscore", href: "https://www.flashscore.com/" },
  ];

  return (
    <>
      <section className="flex px-16 py-10 footer-bg text-center">
        <div className="w-full mx-auto box-border block py-4 max-w-[900px]">
          <div className="flex flex-col md:flex-row justify-center items-top gap-12 mb-12">
            <FooterSection title={i18n("recommendation")} items={goodSites} />
            <FooterSection title={i18n("worldCup")} items={worldCup} />
            <FooterSection title={i18n("scoreSites")} items={score} />
          </div>
          <div className="flex flex-col md:flex-row justify-center items-top gap-12 mb-12">
            <div>
              <h2 className="text-text-second-gray font-bold text-base">
                {i18n("Register")}
              </h2>
              <DecorationLine />
              <div className="flex flex-col gap-4">
                <a
                  href="https://emoji168.911win.vip/casino"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-main-color hover:bg-orange-button-hover rounded-md text-white hover:text-black py-3 px-4"
                >
                  {i18nCommon("signupNow")}
                </a>
                <a
                  href="https://emoji168.911win.vip/casino"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-light-yellow hover:bg-common-border rounded-md text-white hover:text-black py-3 px-4"
                >
                  {i18nVideo("Free trial")}
                </a>
              </div>
            </div>
            <div>
              <h2 class="text-text-second-gray font-bold text-base">
                CONTACT US
              </h2>
              <DecorationLine />
              <div className="flex items-center justify-center gap-4">
                <ShareButton />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-4">
            <img
              alt={COMMON_ALT}
              src={stylesConfig.mainLogo}
              className="w-[100px]"
            />
            <p className="text-light-black text-xs">2005-2024 911win</p>
          </div>
        </div>
      </section>
      <ServicePing />
    </>
  );
};

export default Footer;
