import React from "react";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdCasino } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";

const MenuItem = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  const itemLinks = [
    // { to: "/", text: i18n("Home") },
    { to: "/about", text: i18n("About Us") },
    { to: "/guidance", text: i18n("Guidance") },
    { to: "/news", text: i18n("News") },
    { to: "/products", text: i18n("Products") },
    { to: "/worldCup", text: i18n("World Cup") },
    { to: "/promotion", text: i18n("Promotion") },
    { to: "/agents", text: i18n("Agents") },
    { to: "/vip", text: i18n("VIP") },
    { to: "/app", text: i18n("App") },
    // { to: "/soccer", text: i18n("Soccer") },
  ];

  return (
    <section className="gap-[10px] justify-between items-center hidden md:flex">
      <div className="flex gap-[10px]">
        {itemLinks.map((item, index) => {
          return (
            <Link
              to={item.to}
              className="flex items-center menu-font font-bold text-base-deep-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition"
            >
              {item.text}
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default MenuItem;
