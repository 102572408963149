import React from "react";
import { useTranslation } from "react-i18next";

const ExtraLinks = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  const links = [
    { href: "https://911win.co", text: `${i18n("extraLink")}1` },
    { href: "https://911win.live", text: `${i18n("extraLink")}2` },
    { href: "https://911win.fun", text: `${i18n("extraLink")}3` },
    { href: "https://911win.vip", text: `${i18n("extraLink")}4` },
    { href: "https://win911bet.com", text: `${i18n("extraLink")}5` },
    { href: "https://911live.vip", text: `${i18n("extraLink")}6` },
    { href: "https://911live.online", text: `${i18n("extraLink")}7` },
    { href: "https://911vn.vip", text: `${i18n("extraLink")}8` },
    { href: "https://911vn.me", text: `${i18n("extraLink")}9` },
  ];
  return (
    <div className="grid grid-rows-3 grid-flow-col md:grid-rows-2 my-0 md:my-4 justify-center items-center bg-menu-background">
      {links.map((link, index) => (
        <a
          key={index}
          href={link.href}
          target="_blank"
          rel="noreferrer"
          className="bg-base-white hover:bg-main-color text-sm rounded-md text-black hover:text-white px-2 py-2 md:py-2 md:px-3 m-2"
        >
          {link.text}
        </a>
      ))}
    </div>
  );
};

export default ExtraLinks;
