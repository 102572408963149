import React from "react";
import { useTranslation } from "react-i18next";

const FooterSection = ({ title, items }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`footer.${key}`);
  return (
    <div>
      <h2 className="text-text-second-gray font-bold text-base">{title}</h2>
      <div className="w-full text-center my-2">
        <div className="h-1 bg-main-color rounded-lg"></div>
      </div>
      <ul>
        {items.map((item, index) => (
          <li
            key={index}
            className="text-left list-disc text-sm text-light-black hover:text-main-color"
          >
            <a href={item.href} target="_blank" rel="noreferrer">
              {item.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterSection;
