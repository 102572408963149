import React from "react";
import { stylesConfig } from "../helpers/stylesConfig";
import { useTranslation } from "react-i18next";

const Step = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const imageNameKey = "imageNames.apply1";
  const imageName = `${stylesConfig[t(imageNameKey)]}`;
  const imageNameKey2 = "imageNames.apply2";
  const imageName2 = `${stylesConfig[t(imageNameKey2)]}`;

  return (
    <>
      <div className="bg-base-white py-12 px-3">
        <div className=" text-center">
          <h2 className="text-center text-xl md:text-4xl mb-4">
            {i18n("1Register as a 911WIN member")}
          </h2>
          <p className="mb-12">
            {i18n("1Register as a 911WIN member")}
            <a
              href="https://emoji168.911win.co/"
              target="_blank"
              rel="noreferrer"
              className="text-main-color "
            >
              www.911win.co
            </a>
            {i18n("1Register to fill")}
          </p>
          <img
            src={stylesConfig.step1}
            alt=""
            className="w-full max-w-[750px] mx-auto"
          />
          <div className="flex flex-col relative justify-center items-center my-4">
            <a
              href="https://emoji168.911win.co/signup.php"
              target="_blank"
              rel="noreferrer"
              className="bg-main-color hover:bg-orange-button-hover rounded-full text-white hover:text-black font-bold py-6 px-12 "
            >
              {i18n("signupNow")}
            </a>
            <p className="my-6">
              {i18n("anyQuestion")}
              <a
                href="https://www.facebook.com/911win911/"
                target="_blank"
                rel="noreferrer"
                className="text-main-color "
              >
                {i18n("fbService")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-base-darkBlue pt-20 pb-12 relative px-3">
        <div className="graphic-shape">
          <div className="elementor-shape" data-negative="false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 700 10"
              preserveAspectRatio="none"
              className="fill-base-white"
            >
              <path d="M350,10L340,0h20L350,10z" />
            </svg>
          </div>
        </div>
        <div className=" text-center">
          <h2 className="text-center text-xl md:text-4xl mb-4 text-white">
            {i18n("2Join member")}
          </h2>
          <p className="mb-12 text-white">{i18n("2Join member2")}</p>
          <img
            src={stylesConfig.fbQrcode}
            alt="911Win"
            className="w-full max-w-[126px] mx-auto"
          />
          <div className="flex flex-col relative justify-center items-center my-4">
            <a
              href="https://emoji168.911win.co/signup.php"
              target="_blank"
              rel="noreferrer"
              className="bg-main-color hover:bg-orange-button-hover rounded-full text-white hover:text-black font-bold py-6 px-12 "
            >
              {i18n("signupNow")}
            </a>
            <p className="my-6 text-white">
              {i18n("anyQuestion")}
              <a
                href="https://www.facebook.com/911win911/"
                target="_blank"
                rel="noreferrer"
                className="text-main-color "
              >
                {i18n("fbService")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-base-white pt-20 pb-12 relative px-3">
        <div className="graphic-shape">
          <div className="elementor-shape" data-negative="false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 700 10"
              preserveAspectRatio="none"
              className="fill-base-darkBlue"
            >
              <path d="M350,10L340,0h20L350,10z" />
            </svg>
          </div>
        </div>
        <div className=" text-center">
          <h2 className="text-center text-xl md:text-4xl mb-4 ">
            {i18n("3Apply")}
          </h2>

          <a
            href="https://emoji168.911win.co/signup.php"
            target="_blank"
            rel="noreferrer"
            className="py-6 px-12"
          >
            <img
              src={imageName}
              alt="911Win"
              className="w-full max-w-[800px] mx-auto"
            />
          </a>
          <a
            href="https://emoji168.911win.co/signup.php"
            target="_blank"
            rel="noreferrer"
            className="py-6 px-12"
          >
            <img
              src={imageName2}
              alt="911Win"
              className="w-full max-w-[800px] mx-auto"
            />
          </a>
          <div className="flex flex-col relative justify-center items-center my-4">
            <p className="my-6">
              {i18n("anyQuestion")}
              <a
                href="https://www.facebook.com/911win911/"
                target="_blank"
                rel="noreferrer"
                className="text-main-color "
              >
                {i18n("fbService")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-base-darkBlue pt-20 pb-12 relative px-3">
        <div className="graphic-shape">
          <div className="elementor-shape" data-negative="false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 700 10"
              preserveAspectRatio="none"
              className="fill-base-white"
            >
              <path d="M350,10L340,0h20L350,10z" />
            </svg>
          </div>
        </div>
        <div className=" text-center">
          <h2 className="text-center text-xl md:text-4xl mb-4 text-white">
            {i18n("4Bet")}
          </h2>
          <p className="mb-12 text-white">{i18n("4Bet2")}</p>
          <img
            src={stylesConfig.step2}
            alt="911Win"
            className="w-full max-w-[750px] mx-auto"
          />
          <div className="flex flex-col relative justify-center items-center my-4">
            <p className="my-6 text-white">
              {i18n("anyQuestion")}
              <a
                href="https://www.facebook.com/911win911/"
                target="_blank"
                rel="noreferrer"
                className="text-main-color "
              >
                {i18n("fbService")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="linearBackground pt-32 pb-24 relative px-3">
        <div className="graphic-shape">
          <div className="elementor-shape" data-negative="false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 700 10"
              preserveAspectRatio="none"
              className="fill-base-darkBlue"
            >
              <path d="M350,10L340,0h20L350,10z" />
            </svg>
          </div>
        </div>
        <div className=" text-center">
          <h1 className="text-center text-5xl md:text-8xl mb-12 font-black text-white">
            WHAT ARE YOU<br></br>WAITING FOR ?
          </h1>
          <p className="my-6 text-4xl text-white">
            {i18n("JoinPlay")}
            <br></br>
            <span className="font-bold">{i18n("NFTPlay")}</span>
          </p>

          <div className="flex flex-col relative justify-center items-center my-4">
            <a
              href="https://www.facebook.com/911win911/"
              target="_blank"
              rel="noreferrer"
              className="text-white text-2xl hover:bg-base-dark border-8 border-base-white hover:border-base-dark font-bold py-6 px-12"
            >
              {i18n("signupNow")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step;
