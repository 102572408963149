import { useTranslation } from "react-i18next";
import PWA1 from "../../images/App/pwa1.webp";
import PWA2 from "../../images/App/pwa2.webp";
import PWA3 from "../../images/App/pwa3.webp";
import PWA4 from "../../images/App/pwa4.webp";
import PWA5 from "../../images/App/pwa5.webp";
import PWA6 from "../../images/App/pwa6.webp";
import { Button } from "semantic-ui-react";
import { FaApple } from "react-icons/fa";

const Iphone = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  return (
    <>
      <a
        href="https://zdere.ttyxuude.com/api/c/3vamd07l"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          size="xl"
          className="w-full !bg-main-color px-3 !my-[30px] !text-white !py-[15px] !flex items-center justify-center !rounded-3xl !shadow-lg"
        >
          <FaApple className="text-6xl mr-3" />
          <span className="text-xl mr-3">{i18n("DownloadNow")}</span>
        </Button>
      </a>
      <p className="text-xl">{i18n("iosHint1")}</p>
      <img
        src={PWA1}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">{i18n("iosHint2")}</p>
      <img
        src={PWA2}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">{i18n("iosHint3")}</p>
      <img
        src={PWA3}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">{i18n("iosHint4")}</p>
      <img
        src={PWA4}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">{i18n("iosHint5")}</p>
      <img
        src={PWA5}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">{i18n("iosHint6")}</p>
      <img
        src={PWA6}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
    </>
  );
};

export default Iphone;
