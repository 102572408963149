import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { FaHeadphonesAlt } from "react-icons/fa";
import { MdKeyboardArrowDown, MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import tawkImg from "../images/service/tawk.webp";
import zaloImg from "../images/service/zalo.webp";
import lineImg from "../images/service/line.webp";
import teleImg from "../images/service/tele.webp";
import fbImg from "../images/service/fb.webp";
import viberImg from "../images/service/viber.webp";

const ServicePing = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`service.${key}`);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpenOverlay = () => {
    setIsOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayOpen(false);
  };

  return (
    <>
      <section className="bottom-[100px] md:right-[50px] right-[15px] z-[999999] fixed flex flex-col gap-[10px] items-center">
        <section
          onClick={handleOpenOverlay}
          className="cursor-pointer text-light-white text-2xl border-b-[#b34600] border-b-[3px] border-x-[1px] border-x-[#da5200] border-t-[#da5200] border-t-[0.5px] shadow-lg bg-main-color shadow flex items-center justify-center rounded-full md:w-[55px] md:h-[55px] w-[44px] h-[44px]"
        >
          <FaHeadphonesAlt />
        </section>
      </section>

      <AnimatePresence>
        {isOverlayOpen && (
          <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[999999] bg-black bg-opacity-50 flex items-center justify-center"
          >
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              className="h-full md:h-auto relative bg-white p-8 pt-0 rounded-none md:rounded-lg shadow-lg"
            >
              <div className="flex justify-between items-center">
                <span></span>
                <div className="text-gray-700 text-xl font-medium px-[20px] my-[20px] text-center">
                  {i18n("service")}
                </div>
                <button onClick={handleCloseOverlay} className="text-2xl">
                  <MdOutlineClose />
                </button>
              </div>

              <div className="px-[20px]">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-[20px] md:gap-[10px] justify-items-center max-w-[800px] my-0 mx-auto">
                  <a
                    href="https://tawk.to/chat/5ec391176f7d401ccbb7d73b/default"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tawkImg} alt="Tawk" />
                  </a>
                  <a
                    href="https://zaloapp.com/qr/p/12kdqg6215ghu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={zaloImg} alt="Zalo" />
                  </a>
                  <a
                    href="https://line.me/ti/p/2kyr2UfYam#~"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={lineImg} alt="Line" />
                  </a>
                  <a
                    href="https://t.me/sub911win"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={teleImg} alt="Telegram" />
                  </a>
                  <a
                    href="https://www.facebook.com/gaming/911win911"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fbImg} alt="Facebook" />
                  </a>
                  <a href="" target="_blank" rel="noopener noreferrer">
                    <img src={viberImg} alt="Viber" />
                  </a>
                </div>
                <div className="text-gray-700 text-xl font-medium px-[20px] my-[20px] text-center">
                  {i18n("contactInformation")}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] text-gray-700 px-[20px]">
                  <div className="flex flex-col items-start border-l border-main-color pl-[10px]">
                    <p className="font-semibold text-main-color text-sm mb-0">
                      {i18n("chineseVoiceService")}
                    </p>
                    <div className="flex flex-col items-start">
                      <span className="font-bold text-xl text-main-color">
                        {window?.communityInfo?.Zalo?.phone
                          ? window?.communityInfo?.Zalo?.phone
                          : "+855 87862496"}
                      </span>
                      <span className="text-sm text-gray-700">
                        {i18n("hint1")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-start border-l border-main-color pl-[10px]">
                    <p className="font-semibold text-main-color text-sm mb-0">
                      {i18n("emailService")}
                    </p>
                    <div className="flex flex-col items-start">
                      <span className="font-bold text-xl text-main-color">
                        911win6688@gmail.com
                      </span>
                      <span className="text-sm text-gray-700">
                        {i18n("hint2")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default ServicePing;
