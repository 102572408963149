import React from "react";
import { Link } from "react-router-dom";
import { stylesConfig } from "../helpers/stylesConfig";
import MenuItem from "../pages/components/MenuBar/MenuItem";
import { LuArrowRightFromLine } from "react-icons/lu";
import MenuContentInner from "./MenuContentInner";
import LanguageSettingsMobile from "./LanguageSettingsMobile";
import { motion, AnimatePresence } from "framer-motion";
import { COMMON_ALT } from "../constant";

const MenuContent = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      className="menu-content fixed z-[9] w-full h-full t-0 r-0 l-0 b-0 black-mask"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="menu-right box-shadow-left"
      >
        <div className="flex flex-col justify-between p-6 flex-1">
          <section>
            <div className="flex items-center justify-between">
              <Link to="/" onClick={onClose}>
                <img
                  alt={COMMON_ALT}
                  src={stylesConfig.mainLogo}
                  className="w-[100px] cursor-pointer mobile_show"
                />
              </Link>
              <LuArrowRightFromLine
                onClick={onClose}
                className="text-base-deep-gray text-xl font-black cursor-pointer"
              />
            </div>
            <MenuContentInner onClose={onClose} />
          </section>
          {/*// 傳遞 onClose 属性給 MenuContentInner*/}
          <section className="mobile_show flex flex-col gap-2 ">
            <LanguageSettingsMobile />
          </section>
        </div>
      </div>
    </div>
  );
};

export default MenuContent;
