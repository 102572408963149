import React from "react";
import { useTranslation } from "react-i18next";
import PWAhintAndroid1 from "../../images/App/PWAhintAndroid1.webp";
import PWAhintAndroid2 from "../../images/App/PWAhintAndroid2.webp";
import { Button } from "semantic-ui-react";
import { BsAndroid2 } from "react-icons/bs";

const Android = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  return (
    <>
      <a
        href="https://zdere.ttyxuude.com/api/c/3vamd07l"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          size="xl"
          className="w-full !bg-main-color !my-[30px] !text-white px-3 !py-[15px] !flex items-center justify-center !rounded-3xl !shadow-lg"
        >
          <BsAndroid2 className="text-6xl mr-3" />
          <span className="text-xl mr-3">{i18n("DownloadNow")}</span>
        </Button>
      </a>
      <img
        src={PWAhintAndroid1}
        className="w-full object-cover mb-[20px] rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">
        {i18n("You can add an icon to the")} Android{" "}
        {i18n("home screen to access")} 911win Casino.
      </p>
      <p className="text-xl">{i18n("HintAndroid")}</p>
      <img
        src={PWAhintAndroid2}
        className="w-full object-cover rounded-[5px]"
        alt="911win Giải trí trực tuyến"
      />
      <p className="text-xl">{i18n("HintAndroid2")}</p>
    </>
  );
};

export default Android;
