import React, { useContext, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { stylesConfig } from "../../../helpers/stylesConfig";
import MenuItem from "./MenuItem";
import LanguageSettings from "../../components/LanguageSettings";
import MenuMobile from "../../../components/MenuMobile";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { COMMON_ALT } from "../../../constant";

const MenuTop = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    } else {
      return false;
    }
  }, [location.pathname]);

  return (
    <div className="flex justify-between items-center">
      <Link to="/">
        <img
          alt={COMMON_ALT}
          src={stylesConfig.mainLogo}
          className="w-[100px] cursor-pointer ml-[16px] mobile_show"
        />
        <img
          alt={COMMON_ALT}
          src={stylesConfig.mainLogo}
          className="w-[100px] cursor-pointer mr-[16px] logo-drop-shadow pc_show"
        />
      </Link>

      <div className="flex justify-between items-center">
        <MenuItem />
        <LanguageSettings />
        <MenuMobile />
      </div>
    </div>
  );
};

export default MenuTop;
