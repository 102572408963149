import React from "react";
import { Helmet } from "react-helmet";
import { stylesConfig } from "../../helpers/stylesConfig";

const WebHelmet = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>911win Top Nhà Cái Uy Tín Nhất Việt Nam</title>
      <meta
        name="description"
        content="911win CASINO nhà cái hàng đầu châu á. Hỗ trợ đăng ký, đăng nhập.Trò chơi miễn phí,kết quả bòng đá trực tuyến,baccarat trực tuyến uy tín"
      />
      <meta content="website" property="og:type" />
      <meta
        property="og:title"
        content="911win Top Nhà Cái Uy Tín Nhất Việt Nam - CHẤT LƯỢNG, AN TOÀN, TẠO NÊN"
      />
      <meta
        property="og:description"
        content="911win CASINO nhà cái hàng đầu châu á. Hỗ trợ đăng ký, đăng nhập.Trò chơi miễn phí,kết quả bòng đá trực tuyến,baccarat trực tuyến uy tín"
      />
      <meta property="og:image" content={stylesConfig.mainLogo} />
      <meta property="og:url" content="https://911win.me/" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0"
      ></meta>
      <link rel="icon" href={stylesConfig.mobileLogo} />
    </Helmet>
  );
};

export default WebHelmet;
