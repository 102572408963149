import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const MenuContentInner = ({ onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const itemLinks = [
    // { to: "/", text: i18n("Home"), icon: <IoHome /> },
    { to: "/about", text: i18n("About Us"), icon: <IoPlanet /> },
    { to: "/guidance", text: i18n("Guidance"), icon: <IoTrophy /> },
    { to: "/news", text: i18n("News"), icon: <IoTrophy /> },
    { to: "/products", text: i18n("Products"), icon: <IoGameController /> },
    { to: "/worldCup", text: i18n("World Cup"), icon: <IoTrophy /> },
    { to: "/promotion", text: i18n("Promotion"), icon: <IoTrophy /> },
    { to: "/agents", text: i18n("Agents"), icon: <IoTrophy /> },
    { to: "/vip", text: i18n("VIP") },
    { to: "/app", text: i18n("App") },
    // { to: "/soccer", text: i18n("Soccer") },
  ];

  return (
    <div className="flex flex-col py-4 justify-between items-center md:hidden">
      {itemLinks.map((item, index) => {
        return (
          <Link
            to={item.to}
            onClick={onClose}
            className="flex items-center menu-font w-full font-bold text-base-deep-gray hover:text-main-color p-3 py-4 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition"
          >
            {/* <span className="mr-2 text-2xl">{item.icon}</span>  */}
            {item.text}{" "}
          </Link>
        );
      })}
    </div>
  );
};

export default MenuContentInner;
