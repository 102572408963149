import "./App.css";
import Wrapper from "./components/Wrapper";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Redirect,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Company from "./pages/Company/About";
import MenuTop from "./pages/components/MenuBar/MenuTop";
import LanguageSettings from "./pages/components/LanguageSettings";
import Footer from "./components/Footer";
import About from "./pages/Company/About";
import { useContext } from "react";
import WebHelmet from "./components/SEO/helmet";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import Detail from "./pages/Company/Detail";
import Teach from "./pages/Company/Teach";
import List from "./pages/Company/List";
import Vip from "./pages/Company/vip";
import DownloadAppPC from "./pages/Company/DownloadAppPC";
import DownloadApp from "./pages/Company/DownloadApp";
import Soccer from "./pages/Company/Soccer";

// const tagManagerArgs = {
//   gtmId: "GTM-KH7FG9T",
// };

// TagManager.initialize(tagManagerArgs);

// TagManager.initialize(tagManagerArgs);
ReactGA.initialize("G-XZCBK03ERR");

function App() {
  return (
    <>
      <WebHelmet />
      <Router>
        <div className="bg-menu-background menu-box-shadow px-4 py-3 fixed top-0 w-full z-10">
          <MenuTop />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/public" element={<Home />} />
          {/*/public是為了 911win.live用的 */}
          <Route path="/company" element={<Company />} />
          <Route path="/about" element={<List iclass={0} />} />
          <Route path="/guidance" element={<List iclass={1} />} />
          <Route path="/news" element={<List iclass={2} />} />
          <Route path="/products" element={<List iclass={3} />} />
          <Route path="/worldCup" element={<List iclass={4} />} />
          <Route path="/promotion" element={<List iclass={5} />} />
          <Route path="/agents" element={<List iclass={6} />} />
          <Route path="/vip" element={<Vip />} />
          <Route path="/app" element={<DownloadAppPC />} />
          <Route path="app" element={<DownloadApp />} />
          <Route path="/soccer" element={<Soccer />} />
          <Route path="/detail/:uid" element={<Detail />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
