import React from "react";

const DecorationLine = () => {
  return (
    <div className="w-full text-center my-2">
      <div className="h-1 bg-main-color rounded-lg"></div>
    </div>
  );
};

export default DecorationLine;
