import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import zh_chs from "./zh_chs.json";
import tw from "./tw.json";
import viet from "./viet.json";
import { userLanguage } from "./detectLang";

const defaultLang = userLanguage();
i18n.use(initReactI18next).init({
  debug: false,
  lng: "viet",
  resources: {
    en: { translation: en },
    tw: { translation: tw },
    viet: { translation: viet },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
