import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageOptions, languageMapping } from "../../src/i18n/config";

const LanguageSettings = () => {
  const { t, i18n } = useTranslation();

  const options = languageOptions.map((option) => ({
    value: option.value,
    label: languageMapping(option.value),
  }));

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setSelectedLanguage(selectedLanguage); // 更新选中的语言
  };
  return (
    <div>
      <select
        onChange={handleChangeLanguage}
        value={i18n.language}
        className="dropdown-menu px-5 py-2.5 rounded-[12px] mx-2"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSettings;
