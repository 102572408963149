import React from "react";
import { Link } from "react-router-dom";
import { stylesConfig } from "../helpers/stylesConfig";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LottieAnimationOne from "./LottieAnimationOne";
import LottieAnimationTwo from "./LottieAnimationTwo";
import LottieAnimationThree from "./LottieAnimationThree";

const IntroBlock = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  return (
    <section className="flex flex-col gap-20 py-20">
      <div className="flex flex-col md:flex-row px-4 justify-center items-center">
        <LottieAnimationOne />
        <div className="w-full md:max-w-[50%] md:ml-8">
          <div className="mb-6">
            <h2 className="text-center md:text-left text-xl md:text-4xl flex items-center justify-center md:justify-start mb-4">
              {i18n("whatIs")}
              <span className="flex items-center">
                <img
                  className="w-[74px] h-[34px] mx-2"
                  src={stylesConfig.LogoDark}
                  alt="911Win"
                />
                {i18n("E-sports platform")}？
              </span>
            </h2>
            <div className="w-full md:w-[40%] text-center mb-4">
              <div className="h-1 bg-main-color rounded-lg"></div>
            </div>
            <h3 className="text-center md:text-left text-text-second-gray text-base">
              {i18n(
                "Seizing business opportunities in the Metaverse starts with e-sports competitions"
              )}
            </h3>
          </div>
          <p>{i18n("E-sports1")}</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row px-4 justify-center items-center">
        <LottieAnimationTwo />
        <div className="w-full md:max-w-[50%] md:ml-8">
          <div className="mb-6 flex flex-col md:flex">
            <h2 className="text-center md:text-left text-xl md:text-4xl inline-block items-center justify-center md:justify-start mb-4">
              <span className="flex items-center justify-center md:justify-start">
                <img
                  className="w-[74px] h-[34px] mx-2"
                  src={stylesConfig.LogoDark}
                  alt="911Win"
                />
                {i18n("E-sports platform")}
              </span>{" "}
              {i18n("How to make money from NFT games")}？
            </h2>
            <div className="w-full md:w-[40%] text-center mb-4">
              <div className="h-1 bg-main-color rounded-lg"></div>
            </div>
            <h3 className="text-center md:text-left text-text-second-gray text-base">
              {i18n(
                "AI big data accurate prediction allows members to make stable profits"
              )}
            </h3>
          </div>
          <p>{i18n("GameFi plays and earns")}</p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row px-4 justify-center items-center">
        <LottieAnimationThree />
        <div className="w-full md:max-w-[50%] md:ml-8">
          <div className="mb-6">
            <h2 className="text-center md:text-left text-xl md:text-4xl flex items-center justify-center md:justify-start mb-4">
              <span className="flex items-center">
                <img
                  className="w-[74px] h-[34px] mx-2"
                  src={stylesConfig.LogoDark}
                  alt="911Win"
                />
                {i18n("E-sports platform")}
              </span>{" "}
              {i18n("safe")}？
            </h2>
            <div className="w-full md:w-[40%] text-center mb-4">
              <div className="h-1 bg-main-color rounded-lg"></div>
            </div>
            <h3 className="text-center md:text-left text-text-second-gray text-base">
              {i18n("Quick payment in 3 minutes, safe, legal and risk-free")}
            </h3>
          </div>
          <p>{i18n("The NFT game currency")}</p>
        </div>
      </div>
    </section>
  );
};

export default IntroBlock;
