import { AnimatePresence, motion, useWillChange } from "framer-motion";

import { popUpVariant } from "../../animateConstant";
import { useState } from "react";
import { getFixedStyle } from "../../hook/useDeviceType";

import AddToHome_en from "../../images/App/AddToHome_en.webp";
import AddToHome_zh from "../../images/App/AddToHome_zh.webp";
import AddToHome_tag from "../../images/App/AddToHome_tag.webp";
import PWAhint from "../../images/App/PWAhint2.webp";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router";

import Iphone from "./iphone";
import Android from "./android";
import { Menu } from "semantic-ui-react";

const DownloadApp = ({ setShowDownloadApp }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`app.${key}`);

  const willChange = useWillChange();

  const [isClosing, setIsClosing] = useState(false);
  const [activeItem, setActiveItem] = useState("IPHONE");

  const navigate = useNavigate();

  return (
    <section className="fixed top-0 left-0 w-full h-full overflow-scroll no-scrollbar z-[9999] flex items-center justify-center bg-[rgb(0,0,0,0.7)] ">
      {!isClosing && (
        <motion.main
          variants={popUpVariant}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{ willChange }}
          transition={{
            duration: 0.2,
          }}
          className={`${getFixedStyle()} bg-white] z-[9999] backdrop-blur-md flex items-center justify-center px-[10px]`}
        >
          <section className="max-h-[90vh] overflow-y-scroll no-scrollbar bg-base-white pb-[25px] pt-[10px] px-[15px] rounded-xl text-transaction-text max-w-[450px] w-full">
            <section className="flex items-center justify-end text-3xl">
              <IoCloseOutline
                className="cursor-pointer"
                onClick={() => {
                  setShowDownloadApp(false);
                }}
              />
            </section>
            <p className="text-xl mb-0 text-main-color font-semibold">
              {i18n("DownloadNow")}
            </p>
            <Menu
              pointing
              secondary
              className="flex mx-0 my-4"
              style={{
                borderBottom: "2px solid rgba(34, 36, 38, .15)",
              }}
            >
              <Menu.Item
                name="IPHONE"
                active={activeItem === "IPHONE"}
                onClick={() => {
                  setActiveItem("IPHONE");
                }}
                className="p-4"
                style={
                  activeItem === "IPHONE"
                    ? {
                        borderColor: "#1b1c1d",
                        color: "rgba(0, 0, 0, .95)",
                        fontWeight: 700,
                      }
                    : {}
                }
              />
              <Menu.Item
                name="ANDROID"
                active={activeItem === "ANDROID"}
                onClick={() => {
                  setActiveItem("ANDROID");
                }}
                className="p-4"
                style={
                  activeItem === "ANDROID"
                    ? {
                        borderColor: "#1b1c1d",
                        color: "rgba(0, 0, 0, .95)",
                        fontWeight: 700,
                      }
                    : {}
                }
              />
            </Menu>
            {activeItem === "IPHONE" && <Iphone />}
            {activeItem === "ANDROID" && <Android />}
          </section>
        </motion.main>
      )}
    </section>
  );
};

export default DownloadApp;
