import mainLogo from "../images/Logo/eg911-logo.webp";
import mainLogoWhite from "../images/Logo/eg911-logo.webp";
import mobileLogo from "../images/Logo/loggedInLogo.webp";
import LogoDark from "../images/Logo/911logo-black.webp";
import step1 from "../images/show/newstep1.webp";
import step2 from "../images/show/newstep2.webp";
import fbQrcode from "../images/show/fb-qrcode.webp";
import apply1_TW from "../images/show/apply1_TW.webp";
import apply2_TW from "../images/show/apply2_TW.webp";
import apply1_VN from "../images/show/apply1_VN.webp";
import apply2_VN from "../images/show/apply2_VN.webp";

export const stylesConfig = {
  mainLogo: mainLogo,
  mainLogoWhite: mainLogoWhite,
  mobileLogo: mobileLogo,
  LogoDark: LogoDark,
  step1: step1,
  step2: step2,
  fbQrcode: fbQrcode,
  apply1_TW: apply1_TW,
  apply2_TW: apply2_TW,
  apply1_VN: apply1_VN,
  apply2_VN: apply2_VN,
};
