import React from "react";
import Lottie from "lottie-react";
import lottieAnimationData from "../images/Logo/lottie3.json";

const LottieAnimationThree = () => {
  return (
    <div className="w-full md:max-w-[40%]">
      <div className="w-full h-full max-w-[500px] max-h-[400px]">
        <Lottie
          autoplay={true}
          loop={true}
          renderer="canvas"
          animationData={lottieAnimationData}
        />
      </div>
    </div>
  );
};

export default LottieAnimationThree;
