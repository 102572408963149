import React, { useEffect, useState } from "react";
import {
  PiDevicesFill,
  PiGameControllerFill,
  PiWalletFill,
  PiCoinsFill,
} from "react-icons/pi";
import serviceCoin from "../../images/show/service_coin.webp";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ExtraLinks from "../components/ExtraLinks";
import { getList, getListData } from "../../api/api";
import { useNavigate } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const i18nHeader = (key) => t(`header.${key}`);
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [selectedUid, setSelectedUid] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getListData(0); // 獲取"介紹"類別的列表
        if (response.code === "0" && response.data) {
          const formattedData = response.data.map((item) => ({
            uid: item.uid,
            image: (
              <LazyLoadImage
                src={`/updates/${item.hash}/${item.pic0}`}
                alt="serviceCoin"
                className="w-full h-[120px]"
              />
            ),
            text: item.subject,
            date: item.createDate,
          }));
          setServices(formattedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleItemClick = (uid) => {
    setSelectedUid(uid);
    navigate(`/detail/${uid}`);
  };

  return (
    <>
      <div className=" py-14 fixed w-full top-0">
        <ExtraLinks />
      </div>
      <div className="h-[120px] md:h-[140px]"></div>
      <section className="w-full min-h-[100vh] gap-6">
        <div className="flex flex-col px-6 md:px-24 py-12 justify-center items-center">
          <h1 className="text-light-black text-4xl md:text-6xl font-bold text-center">
            {i18nHeader("About Us")}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-4 justify-center items-center  w-full max-w-[1000px] gap-4 my-10">
            {services.map((service, index) => (
              <div
                key={index}
                className="w-full cursor-pointer"
                onClick={() => handleItemClick(service.uid)}
              >
                <div className="flex items-center justify-center w-full h-auto md:h-[255px] text-center service-icon p-5">
                  <div className="flex flex-col justify-center items-center">
                    {service.image}
                    <p className="text-light-black text-base font-normal text-left my-2">
                      {service.text}
                    </p>
                    <p className="text-light-gray text-xs text-left my-2">
                      {service.date}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
