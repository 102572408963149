import Banner from "../../images/vip/vipBanner_mobile.webp";
import PcBanner from "../../images/vip/vipBanner_pc.webp";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import useDeviceType from "../../hook/useDeviceType";
//import { commonOpacity } from "../../animateConstant";
import vip_luxury from "../../images/vip/vip_luxury.webp";
import vip_weekly from "../../images/vip/vip_weekly.webp";
import vip_host from "../../images/vip/vip_host.webp";
import vip_exclusive from "../../images/vip/vip_exclusive.webp";
import vip_upgrade from "../../images/vip/vip_upgrade.webp";
import { Button } from "semantic-ui-react";
import { Navigate, useNavigate } from "react-router";

const Vip = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`vip.${key}`);

  const navigate = useNavigate();

  const deviceType = useDeviceType();

  const data = [
    {
      image: vip_weekly,
      title: i18n("weeklyTitle"),
      description: i18n("weeklyContent"),
    },
    {
      image: vip_host,
      title: i18n("hostTitle"),
      description: i18n("hostContent"),
    },
    {
      image: vip_exclusive,
      title: i18n("exclusiveTitle"),
      description: i18n("exclusiveContent"),
    },
    {
      image: vip_upgrade,
      title: i18n("upgradeTitle"),
      description: i18n("upgradeContent"),
    },
  ];

  return (
    <motion.main
      //   initial={commonOpacity.initial}
      //   animate={commonOpacity.animate}
      //   exit={commonOpacity.exit}
      //   transition={commonOpacity.transition}
      className="bg-second-color py-14 md:py-20 lg:py-14"
    >
      <section className="relative h-[250px] sm:max-w-full max-w-[390px] rounded-lg overflow-hidden mb-[25px]">
        <img
          src={deviceType === "PC" ? PcBanner : Banner}
          className="object-cover sm:w-full sm:h-[285px] h-inherit"
          alt="banner"
        />
        <div className="absolute top-[40px] md:top-[70px] left-[25px] md:px-20">
          <p className="text-4xl text-vip-title font-semibold mb-3">
            {i18n("introTitle")}
          </p>
          <a
            href="https://emoji168.911win.co/casino/signup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              size="tiny"
              className="!bg-main-color p-2 rounded"
              content={i18n("becomeVIP")}
            />
          </a>
        </div>
      </section>
      <section className="sm:flex gap-[20px] md:px-20">
        <section className="bg-footer-bg w-full rounded-lg px-[12px] pb-[12px]">
          <div className="flex justify-between items-baseline">
            <p className="text-2xl font-bold mb-2">{i18n("step")}</p>
          </div>
          <div className="min-h-[114px] md:px-[12%] relative z-[1] flex md:justify-around md:gap-x-[7%]">
            <div className="w-full absolute top-[27.5px] md:top-[35px] left-0 border border-dashed border-main-color"></div>
            {[i18n("step1"), i18n("step2"), i18n("step3")].map(
              (passo, index) => (
                <div
                  key={index}
                  className="relative flex flex-col flex-1 justify-start items-center top-[10px] md:top-[3px]"
                >
                  <div className="rounded-[90px] bg-light-main-bg w-[86px] h-[36px] md:w-[146px] md:h-[64px]">
                    <div className="rounded-[90px] bg-main-color opacity-[0.3] w-full h-full"></div>
                  </div>
                  <div className="text-[12px] w-[76px] h-[26px] md:text-[20px] md:w-[126px] md:h-[44px] font-bold text-white rounded-[90px] bg-main-color absolute top-[5px] md:top-[10px] left-0 right-0 mx-auto flex justify-center items-center z-[1] whitespace-nowrap">
                    {passo}
                  </div>
                  <div className="text-[12px] md:text-[14px] text-color-text-1 text-center mt-[6px] px-[8px] py-[6px] md:px-0 md:py-0 md:mt-[10px] md:w-[274px]">
                    {index === 0 && i18n("step1Inner")}
                    {index === 1 && i18n("step2Inner")}
                    {index === 2 && i18n("step3Inner")}
                  </div>
                </div>
              )
            )}
          </div>
        </section>
      </section>
      <section className="relative sm:max-w-full rounded-lg overflow-hidden m-[25px] md:px-20">
        <div className="mb-6">
          <p className="text-2xl font-bold mb-4">{i18n("vipLevel")}</p>
          <ul className="list-disc pl-5">
            <li>{i18n("10Level")}</li>
          </ul>
        </div>
        <div className="mb-6">
          <p className="text-2xl font-bold mb-4">{i18n("activityIntro")}</p>
          <ul className="list-disc pl-5">
            <li>{i18n("intro1")}</li>
            <li>{i18n("intro2")}</li>
          </ul>
        </div>
        <div className="mb-6">
          <p className="text-2xl font-bold mb-4">{i18n("levelIntro")}</p>
          <ul className="list-disc pl-5">
            <li>{i18n("levelIntro1")}</li>
          </ul>
        </div>
        <div className="mb-4">
          <div className="w-full my-4 overflow-x-auto">
            <table className="min-w-full border border-gray-300 mb-6 overflow-x-auto">
              <thead>
                <tr className="bg-date-color">
                  <th className="border px-4 py-2 bg-thead-bg">
                    {i18n("vipReach")}
                  </th>
                  <th className="border px-4 py-2">VIP1</th>
                  <th className="border px-4 py-2">VIP2</th>
                  <th className="border px-4 py-2">VIP3</th>
                  <th className="border px-4 py-2">VIP4</th>
                  <th className="border px-4 py-2">VIP5</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("depositTotal")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("500w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("1500w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("4500w")}
                  </td>
                  <td className="border px-4 py-2 text-center">{i18n("1y")}</td>
                  <td className="border px-4 py-2 text-center">{i18n("2y")}</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("flowTotal")}
                    <br></br>
                    {i18n("flowTotal1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("3200w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("3.2y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("9.6y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("22.5y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("50y")}
                  </td>
                </tr>
                <tr className="bg-date-color">
                  <th className="border px-4 py-2 bg-thead-bg">
                    {i18n("vipReach")}
                  </th>
                  <th className="border px-4 py-2">VIP6</th>
                  <th className="border px-4 py-2">VIP7</th>
                  <th className="border px-4 py-2">VIP8</th>
                  <th className="border px-4 py-2">VIP9</th>
                  <th className="border px-4 py-2">VIP10</th>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("depositTotal")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("4.2y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("8.5y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("17.5y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("35y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("80y")}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("flowTotal")}
                    <br></br>
                    {i18n("flowTotal1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("100y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("205y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("415y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("830y")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("1600y")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-6">
            <p className="text-2xl font-bold mb-4">{i18n("example")}</p>
            <ul className="list-disc pl-5">
              <li>{i18n("example1")}</li>
              <li>{i18n("example2")}</li>
              <li>{i18n("example3")}</li>
            </ul>
          </div>
        </div>
        <div className="mb-6">
          <p className="text-2xl font-bold mb-4">{i18n("good")}</p>
          <ul className="list-disc pl-5">
            <li>{i18n("good1")}</li>
            <li>{i18n("good2")}</li>
            <li>{i18n("good3")}</li>
            <li>{i18n("good4")}</li>
            <li>{i18n("good5")}</li>
            <li>{i18n("good6")}</li>
            <li>{i18n("good7")}</li>
            <li>{i18n("good8")}</li>
            <li>{i18n("good9")}</li>
          </ul>
        </div>
        <div className="mb-4">
          <div className="w-full my-4 overflow-x-auto">
            {/* <img
              src={vip_col}
              className=" max-w-[1600px] h-full overflow-x-auto"
              alt="vip"
            /> */}

            <table className="min-w-full border border-gray-300 mb-6 overflow-x-auto">
              <thead>
                <tr className="bg-date-color">
                  <th className="border px-4 py-2 bg-thead-bg">
                    {i18n("onlyVIP")}
                  </th>
                  <th className="border px-4 py-2">VIP1</th>
                  <th className="border px-4 py-2">VIP2</th>
                  <th className="border px-4 py-2">VIP3</th>
                  <th className="border px-4 py-2">VIP4</th>
                  <th className="border px-4 py-2">VIP5</th>
                  <th className="border px-4 py-2">VIP6</th>
                  <th className="border px-4 py-2">VIP7</th>
                  <th className="border px-4 py-2">VIP8</th>
                  <th className="border px-4 py-2">VIP9</th>
                  <th className="border px-4 py-2">VIP10</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("freeWithdraw")}
                  </td>
                  <td className="border px-4 py-2 text-center" colSpan={4}>
                    8{i18n("times")}
                  </td>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={6}
                  >
                    ∞
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("dayWithdraw")}
                  </td>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={10}
                  >
                    {i18n("unlimited")}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgradePrize")}
                  </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade2")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade3")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade4")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade5")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade6")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade7")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade8")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade9")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade10")}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("birthdayPrize")}
                  </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("200w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("500w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("600w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("700w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("1000w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("1500w")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("2000w")}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("prizeLock")}
                  </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade2-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade3-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade4-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade5-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade6-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade7-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade8-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade9-1")}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {i18n("upgrade10-1")}
                  </td>
                </tr>

                <tr>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={11}
                  >
                    {i18n("lockInfo")}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("good6")}
                  </td>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={10}
                  >
                    √
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("good7")}
                  </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={7}
                  >
                    √
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("good8")}
                  </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={6}
                  >
                    √
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-center">
                    {i18n("good9-1")}
                  </td>
                  <td className="border px-4 py-2 text-center" colSpan={2}>
                    {i18n("backfee")}
                    <br></br>
                    {i18n("backfee-1")}
                  </td>
                  <td className="border px-4 py-2 text-center" colSpan={2}>
                    {i18n("backfee")}
                    <br></br>
                    {i18n("backfee-2")}
                    <br></br>
                    {i18n("backfee-3")}
                  </td>
                  <td
                    className="border px-4 py-2 text-center text-xl"
                    colSpan={6}
                  >
                    ∞
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-6">
            <ul className="list-disc pl-5">
              <li>{i18n("rule1")}</li>
              <li>{i18n("rule2")}</li>
              <li>{i18n("rule3")}</li>
              <li>{i18n("rule4")}</li>
              <li>{i18n("rule5")}</li>
              <li>{i18n("rule6")}</li>
              <li>{i18n("rule6-1")}</li>
              <li>
                {i18n("rule6-2")}
                <br></br>
                {i18n("rule6-2-1")}
                <br></br>
                {i18n("rule6-2-2")}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="relative sm:max-w-full rounded-lg overflow-hidden m-[25px] md:px-20">
        <div className="mb-4">
          <p className="text-2xl font-bold mb-6">{i18n("vipIntroduction")}</p>
          <div className="mt-2 flex flex-col gap-[10px]">
            <div className="grid md:grid-cols-2 gap-[10px]">
              {data.map((item, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-r from-[#ffa568] to-[#fff8f4] rounded-lg p-[15px] h-[120px] flex items-center"
                >
                  <div
                    className="w-[90px] h-[90px] mr-[15px] shrink-0 bg-no-repeat bg-cover"
                    style={{ backgroundImage: `url(${item.image})` }}
                  ></div>
                  <div>
                    <div className="text-[18px] text-white font-semibold mb-[4px]">
                      {item.title}
                    </div>
                    <p className="text-[14px] line-clamp-3">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </motion.main>
  );
};

export default Vip;
