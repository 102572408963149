import React from "react";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  TelegramShareButton,
  TelegramIcon,
  TwitterIcon,
  LineIcon,
} from "react-share";
import { FaInstagram, FaYoutube, FaTiktok, FaFacebookF } from "react-icons/fa";
import { PiTelegramLogoLight } from "react-icons/pi";
import { IoMailOutline } from "react-icons/io5";
import { FaXTwitter } from "react-icons/fa6";

const links = [
  {
    href: "https://www.facebook.com/911win911",
    className:
      "mt-4 hover:scale-110 rounded-[50%] w-[30px] h-[30px] flex justify-center items-center bg-[#3b5998]",
    icon: <FaFacebookF className="text-xl text-white font-bold" />,
    rel: "noreferrer",
    target: "_blank",
  },
  {
    href: "https://www.instagram.com/911win_viet/",
    className:
      "mt-4 hover:scale-110 rounded-[50%] w-[30px] h-[30px] flex justify-center items-center bg-[#d62976]",
    icon: <FaInstagram className="text-xl text-white font-bold" />,
    rel: "noreferrer",
    target: "_blank",
  },
  {
    href: "https://www.youtube.com/@911win9",
    className:
      "mt-4 hover:scale-110 rounded-[50%] w-[30px] h-[30px] flex justify-center items-center bg-[#cc181e]",
    icon: <FaYoutube className="text-xl text-white font-bold" />,
    rel: "noreferrer",
    target: "_blank",
  },
  {
    href: "https://www.tiktok.com/@giitr911wingameon",
    className:
      "mt-4 hover:scale-110 rounded-[50%] w-[30px] h-[30px] flex justify-center items-center bg-[#2af0ea]",
    icon: <FaTiktok className="text-xl text-white font-bold" />,
    rel: "noreferrer",
    target: "_blank",
  },
  {
    href: "https://twitter.com/911winCo",
    className:
      "mt-4 hover:scale-110 rounded-[50%] w-[30px] h-[30px] flex justify-center items-center bg-[#141d26]",
    icon: <FaXTwitter className="text-xl text-white font-bold" />,
    rel: "noreferrer",
    target: "_blank",
  },
];

const ShareButton = () => {
  return (
    <div className="flex gap-4 justify-center items-center mt-2">
      {/* {socialButtons.map((button, index) => (
        <button.Component
          key={index}
          className="hover:scale-110"
          url={button.url}
          quote={button.quote}
          hashtag={button.hashtag}
        >
          <button.IconComponent size={32} round />
        </button.Component>
      ))} */}
      {links.map((link, index) => (
        <a
          key={index}
          href={link.href}
          className={link.className}
          rel={link.rel}
          target={link.target}
        >
          {link.icon}
        </a>
      ))}
    </div>
  );
};

export default ShareButton;
