import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageOptions, languageMapping } from "../../i18n/config";

const LanguageSettings = () => {
  const { t, i18n } = useTranslation();

  const options = languageOptions.map((option) => ({
    value: option.value,
    label: languageMapping(option.value),
  }));

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  return (
    <div className="cursor-pointer hidden md:block">
      <select
        onChange={handleChangeLanguage}
        value={i18n.language}
        className=" bg-base-gray dropdown-menu px-3 md:px-5 py-1.5 md:py-3 rounded-[12px] mx-2 cursor-pointer"
      >
        {options.map((option) => (
          <option
            className="cursor-pointer "
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSettings;
